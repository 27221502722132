
import {filter} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { RoutingState } from './catelog/services/routingState.service';
import {Component, ViewEncapsulation} from '@angular/core';
import {BaMenuService} from "../theme/services/baMenu/baMenu.service";
import {PAGES_MENU} from "./pages.menu";
import {Routes, Router, NavigationEnd} from "@angular/router";
import {DataService} from "./data.service";
import {GlobalState} from "../global.state";
import {params} from "./params";
import {urls} from "./urls";
import * as _ from "lodash";
declare var $: any;
import {mod} from 'ngx-bootstrap/chronos/utils';
import {environment} from "../../environments/environment";
import { CommonapiService } from './commonapi.service';

@Component({
  selector: 'pages',
  encapsulation: ViewEncapsulation.None,
  styleUrls:['pages.scss'],
  template:
  `
  <div class="mogb_page">
    <ba-header></ba-header>
    <div class="mogb_mainwrap sidebaractive">
      <aside class="mgb_sidebar active">
            <ba-sidebar></ba-sidebar>
      </aside>
      <div class="mogb_pagearea">
        <router-outlet></router-outlet>
        <div class="floating-bar">
          <div class="floating-inner">
              <div class="right">
                  <a [href]="'mailto:' + getEmailId()" target="_blank"> <i class="ri-mail-fill"></i> </a>
              </div>
              <div class="left">
                  <h2> Mail us at</h2>
                  <a [href]="'mailto:' +getEmailId()" target="_blank">{{getEmailId()}}</a>
              </div>
          </div>
        </div>
      </div>
    </div>
    <ba-back-top position="200"></ba-back-top>
  </div>
  `
})

export class Pages {
  isDashboard:boolean=false;
  public isSAPintegrated : boolean;
  public isCorporateClient : boolean;
  permissions: any;
  plantId: any;
  selectedModule: any;
  companyId: any;
  buyerIdArray: any[] = [];
  newUm: boolean;
  prevUmFlag: boolean;
  showProjects: boolean;

  moduleSubscription: Subscription;
  plantSubscription: Subscription;
  companySubscription: Subscription;


  constructor(private routingState: RoutingState,public router:Router,public _dataService: DataService,public _menuService : BaMenuService,public _state:GlobalState, private _commonapiService: CommonapiService) {
    this.isSAPintegrated=this._dataService.isSAPintegratedGlobal;
    this.isCorporateClient=this._dataService.isCorporateClientGlobal;
    this._dataService.bSubject.subscribe((value)=>{
      this.isSAPintegrated=value;
    });
    this._dataService.cSubject.subscribe((value)=>{
      this.isCorporateClient=value;
      // console.log("CORPORATE CLIENT",this.isCorporateClient);
    })
    this._state.subscribe('isDashboard',(value)=>{
      this.isDashboard = value;
      // console.log("isDashboard "+value);
      this._state.notifyDataChanged('menu.isCollapsed', value);
    });
    this._state.subscribe('plantId',(value)=>{
      this.getPermissions();
      // console.log("PLAAAAAAAAAAAANT: "+value);
      // this._dataService.buyerPlantId = value;
    });
    this._state.subscribe('companyId',(value)=>{
      this.getPermissions();
      // console.log("company changed to.....................: "+value);
    });
    this.companySubscription = this._dataService.compSubject.subscribe((value) => {
      this.companyId = (value) ? value : this._dataService.getCurrCompanyID().toString();
    });

    this.plantSubscription = this._dataService.pSubject.subscribe((value) => {
      this.buyerIdArray = [];
      this.plantId = value;
      if (value !== '-1') {
        this.companyId = this._dataService.getCurrCompanyID().toString();
      } else {
        this.buyerIdArray = this.companyId ? this._dataService.getAllPlantsByCurrCompany(this.companyId) : [this._dataService.getBuyerPlantId()];
      }
      this.getPermissions();
    });

    this.moduleSubscription = this._dataService.moduleSubject.subscribe((module) => {
      this.selectedModule = module;
      this.getPermissions(true);
    });
  }

  servicesShowList = ["pr","rfq","ra"];

  makeMenuItems(){
    let menu = _.cloneDeep(PAGES_MENU);
    let modules:any[] = [];
    // modules.push(menu[0]['children'].find(x => x.path == 'bi'));
    if(!this.permissions){
      this.router.navigateByUrl('/logout');
      return;
    }
    for(let module of menu[0]['children']){
      if (module.path === 'projects' && this.showProjects) {
        modules.push(module);
      }
      else if(module.path == ''){
        let mods = [];
        // console.log("Current Permissions:",permissions,this._dataService.getBuyerPlantId());
        /*************************** Procurement ******************************************/
        for(let mod of module['children']) {
          if(mod.path === 'orders') {
            for(let ordersChild of mod.children) {
              if(this.permissions[ordersChild.path[3].toUpperCase()] && this.permissions[ordersChild.path[3].toUpperCase()] > 0){
                mods.push(ordersChild);
              }    
            }
          }

          if (!this.isSAPintegrated) {
            // console.log("is sap wali value in pages componenet", this.isSAPintegrated);
            if(this.permissions[mod.path.toUpperCase()] && this.permissions[mod.path.toUpperCase()] > 0
              // &&  mod.path.toUpperCase()!="PR"
              && mod.path.toUpperCase()!="RA"){
              // console.log("MODULESSS TESTING",permissions[mod.path.toUpperCase()])
              if(!this._dataService.getViewSetting() && this.servicesShowList.indexOf(mod.path)<0 ) {
                continue;
              }
              // console.log("Pushing.." + mod.path, permissions[mod.path.toUpperCase()]);
              mods.push(mod);
            }
            if(mod.path.toUpperCase()=="SUPPLIERS" && this.permissions['SUPP']) mods.push(mod);
            if(mod.path.toUpperCase()=="EXT" && this.permissions['POEXT']) mods.push(mod);
          }
          else {
            if(mod.path.toUpperCase()=="ASN" &&mod.path.toUpperCase()=="INV") mods.push(mod);
            // else if(mod.path.toUpperCase()=="PR" && (this._dataService.getCurrentCompanyId() == '8991' || this._dataService.getCurrentCompanyId() == '8970')){
            //   mods.push(mod);
            // }
            else if(this.permissions[mod.path.toUpperCase()] &&  mod.path.toUpperCase()!="RFQ" &&  mod.path.toUpperCase()!="RFQNEW"
                && mod.path.toUpperCase()!="RA" && this.permissions[mod.path.toUpperCase()] > 0){
              // && mod.path.toUpperCase()!="RA" &&  mod.path.toUpperCase()!="PR" && permissions[mod.path.toUpperCase()] > 0){
              if(!this._dataService.getViewSetting() && this.servicesShowList.indexOf(mod.path)<0 ) {
                continue;
              }
              // console.log("Pushing.." + mod.path, permissions[mod.path.toUpperCase()]);
              mods.push(mod);
            }
          }

          // if(Array.isArray(mod.path)){
          //   if(permissions[mod.path[2].toUpperCase()] && permissions[mod.path[2].toUpperCase()] > 0){
          //     if(permissions[mod.path[2].toUpperCase()]==2){
          //       mod.path.push('create');
          //     }
          //     // console.log("Pushing.."+mod.path,permissions[mod.path.toUpperCase()]);
          //     mods.push(mod);
          //   }
          // }

        }
        if(mods.length > 0) {
          module['children'] = mods;
          modules.push(module);
        }

        // console.log("current moduless ", modules);
      }
      /*******************Access Control Display - Pages************************/
      else if(this.permissions[module.path.toString().toUpperCase()] > 0){
        if(!this._dataService.getViewSetting() && this.servicesShowList.indexOf(module.path.toString())<0 ) {
          continue;
        }
        // console.log("Pushing.."+module.path,permissions[module.path.toString().toUpperCase()]);
        modules.push(module);
      }

    }
    menu[0]['children'] = modules;
    modules = [];

    /************* Master Data ***************/
    for (let i = 0; i < menu[2]['children'].length; i++) {
      const module = menu[2]['children'][i];
      if (!this.permissions[module.path[2].toString().toUpperCase()] || this.permissions[module.path[2].toString().toUpperCase()] < 1) {
          menu[2].children.splice(i, 1);
          i = i - 1;
      }
    }

    /************* Manage ***************/
    // running manage code for both old and new user management modules
    let index: any;
    let masterData;
    if (menu[2]['children'].length === 0) {
      menu.splice(2, 1);
      index = this.newUm ? 2 : 3;
      masterData = false;
    } else {
      masterData = true;
      index = this.newUm ? 3 : 4;
    }
    modules = [];
    for(let module of menu[index]['children']) {
      if(this.permissions['UM'] && this.permissions['UM'] > 0 || module.path[2] === 'account') {
        if(module.path[2].includes('um') && this.permissions['UM'] < 1) {
          continue;
        }
        // console.log('module pushed', module.path);
        modules.push(module);
      }
    }
    if(modules.length > 0) {
      let menuIndex = masterData ? 3 : 2;
      if(this.newUm) {
        menu[menuIndex]['children'] = modules;
        menu.splice(menuIndex + 1, 1);
      } else {
        menu[menuIndex + 1]['children'] = modules;
        menu.splice(menuIndex, 1);
      }
    } else {
      menu.splice(2, 2);
    }

    for(let i=0; i< menu.length; i++){
      let menuItem = menu[i];
        if (!this.showProjects && menuItem.data && menuItem.data.menu && menuItem.data.menu.title && menuItem.data.menu.title === "Projects") {
          menu.splice(i, 1);
        }
    }
    if (!this.permissions.hasOwnProperty("UM") || this.permissions["UM"] < 1) {
      for (const menuItem of menu) {
        if (menuItem.data && menuItem.data.menu && menuItem.data.menu.title && menuItem.data.menu.title === "Manage") {
          menuItem["children"].splice(1, 4);
        }
      }
    }
    if (!this.permissions.hasOwnProperty("MC") || this.permissions["MC"] < 1) {
      for (let i = 0; i < menu.length; i++) {
        let menuItem = menu[i];
        if (menuItem.data && menuItem.data.menu && menuItem.data.menu.title && menuItem.data.menu.title === "Moglix Catalog") {
          menu.splice(i, 1);
        }
      }
    }
    if (!this.permissions.hasOwnProperty("SUP") || this.permissions["SUP"] < 1) {
      for(let m = 0; m < menu.length; m++) {
        if(menu[m].data && menu[m].data.menu && menu[m].data.menu.module === 'SUP') {
          menu.splice(m, 1);
        }
      }
    }
    if(!this.permissions.hasOwnProperty("Report") || this.permissions["Report"]<1){
      for(let m = 0; m<menu.length; m++){
        if(menu[m].data && menu[m].data.menu && menu[m].data.menu.module == 'Report'){
          menu.splice(m,1);
        }
      }
    }

    if(!this.permissions.hasOwnProperty("Recommendation") || this.permissions["Recommendation"]<1){
      for(let m = 0; m<menu.length; m++){
        if(menu[m].data && menu[m].data.menu && menu[m].data.menu.module == 'Recommendation'){
          menu.splice(m,1);
        }
      }
    }
    if (!this.permissions.hasOwnProperty("GS") || this.permissions["GS"] < 1) {
      for(let m = 0; m < menu.length; m++) {
        if(menu[m].data && menu[m].data.menu && menu[m].data.menu.module === 'GS') {
          menu.splice(m, 1);
        }
      }
    }

    // for ARC and RFQ Catalog
    for (const menuItem of menu) {
      if (menuItem.data && menuItem.data.menu && menuItem.data.menu.title && menuItem.data.menu.title === "Catalog") {
        for(let c = 0; c < menuItem.children.length; c++) {
          let child = menuItem.children[c];
          if ((!this.permissions.hasOwnProperty("TC") || this.permissions["TC"] < 1) && child.data.menu.module === 'TC') {
            menuItem.children.splice(c,1);
            break;
          } 
          if ((!this.permissions.hasOwnProperty("MM") || this.permissions["MM"] < 1) && child.data.menu.module === 'MM') {
            menuItem.children.splice(c,1);
            break;
          }
        }
      }
    }

    // Don't show catalog if both MM and TC permission is not there
    if ((!this.permissions.hasOwnProperty("TC") || this.permissions["TC"] < 1) && (!this.permissions.hasOwnProperty("MM") || this.permissions["MM"] < 1)) {
      for(let i = 0; i < menu.length; i++) {
        let menuItem = menu[i];
        if (menuItem.data && menuItem.data.menu && menuItem.data.menu.title && menuItem.data.menu.title === "Catalog") {
          menu.splice(i, 1);
        }
      }
    }
    const url = window.location.href;
    if (url.indexOf("ge.moglix.com") > -1 ) {
      menu.splice(3, 1);
    }
    // modules.push(menu[0]['children'].find(x => x.path == ''));
    // modules.push(menu[0]['children'].find(x => x.path == 'logout'));


    this._menuService.updateMenuByRoutes(<Routes>menu);
    this._dataService.setCookie('prevUmFlag', this.newUm.toString());
    //console.log("Menu : " , menu);
  }

  booleanCompare(a: boolean, b: boolean) {
    if(a==false && b==false){
      return false
    }else if(a==true && b==true){
      return false;
    }else{
      return true;
    }
  }

  public getPermissions(flag ?: any) {
    this.plantId = this._dataService.getBuyerPlantId();
    let req = {
      'idBranch': (this.plantId === '-1') ? null : this.plantId,
      'idUser': this._dataService.getUserId()
    }
    if (this.plantId === '-1') {
      req['plantIds'] = this.buyerIdArray;
    }
    this._dataService.callRestful('POST', environment.URLS.AUTH_URL + urls.AUTH.GET_IS_SAP_INTREGATED, { 'idCompany': this.companyId}).subscribe(
      (res) => {
          if (res['successful']) {
              this.newUm = res?.data?.company?.umAssigned ? true : false;
              this._dataService.setCookie('umFlag', this.newUm.toString())
              this._dataService.callRestful('POST', environment.URLS.AUTH_URL + this._commonapiService.getUrls().UM.AUTH.GET_BRANCH_ACCESS, req).subscribe(
                (data) => {
                  if(data['successful']){
                    this.permissions = data['data']['branchModules']['modulesPermission'];
                    this._dataService.hideMessage();
                    this._dataService.setModuleAccess(data['data']['branchModules']['modulesPermission']);
                    this.prevUmFlag = this._dataService.getCookie('prevUmFlag') === 'true' ? true : false;
                    // console.log('flags', this.selectedModule);
                    setTimeout(() => {
                      if ((this.permissions[this.selectedModule] === undefined || this.permissions[this.selectedModule] === 0) && this.selectedModule !== 'UM'){
                        // console.log('pages redirection', this.selectedModule);
                        this.router.navigateByUrl('/pages/orders/po');
                      }
                    }, 1000);
                    // else if (this.booleanCompare(this.prevUmFlag, this.newUm)){
                    //   this.router.navigateByUrl('/pages/orders/po');
                    // }
                    if (!flag) {
                      this._dataService.callRestful('GET', `${environment.URLS.CUSTOMER_URL}${this.plantId}`).subscribe((data) => {
                        if(data.success){
                          this.showProjects = data?.data || false;
                        }
                        this.makeMenuItems();
                      },
                      (error) =>  { this.makeMenuItems(); });
                    }
                  }
            });
          }
      }
    );
  }

  ngOnInit() {
    this.companyId = this._dataService.getCurrCompanyID().toString();
    this._dataService.getBuyerPlantId() !== '-1' ? this.plantId = this._dataService.getBuyerPlantId() : this.buyerIdArray = this._dataService.getAllPlantsByCurrCompany(this.companyId);

    this.routingState.loadRouting();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e=>{
        if( this.routingState.getPreviousUrl() == '/pages/pm/product-list'){
          this._dataService.setProductListFlag(true);
        }else{
          this._dataService.setProductListFlag(false);
        }
    });
    $('.mogb_menubutton').click(()=>{
      if($('.mgb_sidebar').hasClass('active')) {
        $('.mgb_sidebar').removeClass('active').addClass('inactive');
      }
      else{
        $('.mgb_sidebar').removeClass('inactive').addClass('active');
      }
      $('.mogb_mainwrap').toggleClass('sidebaractive');
    });

    this.permissions = this._dataService.getCurrentPermissions();
    this.getUMFlag();
  }

  public getUMFlag() {
    $('#loader').css('display', 'block');
    const reqObj = {
        'idCompany': this.companyId,
    };
    this._dataService.callRestful('POST', environment.URLS.AUTH_URL + urls.AUTH.GET_IS_SAP_INTREGATED, reqObj).subscribe(
        (data) => {
            $('#loader').css('display', 'none');
            if (data['successful']) {
                this.newUm = data?.data?.company?.umAssigned ? true : false;
                this._dataService.setCookie('umFlag', this.newUm.toString());
                this._dataService.callRestful('GET', `${environment.URLS.CUSTOMER_URL}${this.plantId}`).subscribe((data) => {
                  if(data.success){
                    this.showProjects = data?.data || false;
                  }
                  this.makeMenuItems();
                },
                (error) =>  { this.makeMenuItems(); }
                );
                // console.log('this.newUm', this.newUm);
            }
        }
    );
  }

  getEmailId() :string{
    if(this.companyId === '61305'){
      return "esi.support@moglix.com"
    }
    else{
      return "support@moglix.com"
    }
  }


  ngAfterViewInit(){}

  ngOnDestroy() {
    this.moduleSubscription.unsubscribe();
    this.plantSubscription.unsubscribe();
    this.companySubscription.unsubscribe();
  }

}
